body {
    font-family: Arial, Helvetica, sans-serif;
}

.button {
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    background-color: #41b883;
    color: white;
    top: 50%;
    transform: translate(0, -100%);
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 1px solid rgba(0, 116, 52, 0.5);
}

.button.disable {
    background-color: #369b6d;
    opacity: .5;
    cursor: default;
}

.container .image {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    background-size: cover;
}

.container {
    display: block;
    position: relative;
    width: 80vw;
    height: 45vw;
    margin: auto;
}

.button:nth-child(1) {
    left: 10px;
}
.button:nth-child(2) {
    right: 10px;
}

.status {
    display: block;
    text-align: center;
    font-size: 32px;
}